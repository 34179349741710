import React, { useEffect } from "react";
import Helmet from "../components/Helmet/Helmet.js";
import { Container, Row } from "reactstrap";
import "../styles/home.css";
import AllFoods from "./News.jsx";
import TestimonialSlider from "../components/UI/slider/TestimonialSlider.jsx";
import MainCard from "./MainCard.jsx";
import Collections from "../components/Collections/Collections";
import Recommendations from "../components/Recommendations/Recommendations.jsx";
import Videoplay from "../components/videoplay-block/Videoplay.jsx";
import News from "./News.jsx";
import Questions from "../components/Questions/Questions.jsx";
import HomeImg from "../assets/images/home-img.png";
import Box from "@mui/material/Box";

const Home = () => {
  return (
    <Helmet title="Home">
      <section>
        <Container>
          <TestimonialSlider />
        </Container>
      </section>
      <section className="delimiter"></section>
      <section>
        <Recommendations />
        <section className="delimiter"></section>
        <Questions />
        <section className="delimiter"></section>
        <News />
        <section className="delimiter"></section>
      </section>
    </Helmet>
  );
};

export default Home;
