import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CartItem from "../components/UI/cart/CartItem";
import axios from "axios";
import "../styles/checkout.css";
import { cartActions } from "../store/shopping-cart/cartSlice";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Collections from "../components/Collections/Collections";
import EmptyCart from "../assets/images/icon__empty-cart.png";
import { useForm } from "react-hook-form";
import { Radio } from "@mui/material";
import { pink } from "@mui/material/colors";

const Checkout = () => {
  const [enterName, setEnterName] = useState("");
  const [enterNumber, setEnterNumber] = useState("");
  const [enterAddress, setEnterAddress] = useState("");
  const [enterHouse, setEnterHouse] = useState("");
  const [isDelivery, setIsDelivery] = useState(true); // Изначально установите на доставку
  const shippingInfo = [];
  const cartTotalAmount = useSelector((state) => state.cart.totalAmount);
  const cartProducts = useSelector((state) => state.cart.cartItems);
  const [paymentOption, setPaymentOption] = useState("option1");
  const shippingCost = 0.95;
  const totalAmount = isDelivery
    ? cartTotalAmount
    : Math.floor(cartTotalAmount * shippingCost);

  const dispatch = useDispatch();

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleClearCart = () => {
    dispatch(cartActions.clearCart());
  };

  const handleDeliveryChange = () => {
    setIsDelivery(true); // Выбрана доставка
  };

  const handleSelfPickupChange = () => {
    setIsDelivery(false); // Выбран самовывоз
    setEnterAddress("Самовывоз");
    setEnterHouse("Самовывоз");
  };
  useEffect(() => {
    const isCartEmpty = cartProducts.length === 0;
    const checkoutBtn = document.querySelector(".checkout__btn");

    if (checkoutBtn) {
      checkoutBtn.disabled = isCartEmpty;
    }
  }, [cartProducts]);
  console.log();
  const submitHandler = (e) => {
    e.preventDefault();
    const userShippingAddress = {
      name: enterName,
      Address: enterAddress,
      phone: enterNumber,
      house: enterHouse,
    };
    handleShow();
    handleClearCart();
    shippingInfo.push(userShippingAddress);
    console.log(handleSelfPickupChange());
    // Добавим код для отправки данных в телеграм
    const message = `
      Новый заказ:
      Имя: ${enterName}
      Номер: ${enterNumber}
      Адрес доставки: ${enterAddress}
      Квартира/Дом: ${enterHouse}
      Метод доставки: ${isDelivery ? "Доставка" : "Самовывоз"}
      Название товара и количество: ${cartProducts
        .map(
          (product) =>
            `\n${" ".repeat(10)}${product.title}: ${product.quantity}шт.`
        )
        .join(" ")}
      Цена: ${totalAmount} сом
    `;

    const botToken = "6653204037:AAH_CeA7SSuYr1icKV8sLVhZuOYuDYzqazU";
    const chatId = "-1002083810444";

    try {
      axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        chat_id: chatId,
        text: message,
      });

      console.log("Form data sent to Telegram successfully!");
    } catch (error) {
      console.error("Error sending form data to Telegram:", error);
    }
    // window.location.reload();
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // Ваша логика отправки данных
    console.log("Form submitted:", data);
  };
  const controlProps = (key) => ({
    checked: paymentOption === key,
    onChange: () => setPaymentOption(key),
    value: key,
    name: "paymentOption",
    inputRef: register(),
  });

  return (
    <Helmet title="Корзина">
      <section>
        <h1 className="checkout-head">Моя корзина</h1>
        {cartProducts.length === 0 ? (
          <div className="empty-cart">
            <img src={EmptyCart} alt="" />
            <h3 className="empty-cart__head"> ВАША КОРЗИНА ПУСТА</h3>
            <p className="empty-cart__desc">
              Выберите товары из нашего каталога и добавьте их в корзину
            </p>
            <Link className="empty-cart__btn" to="/">
              На главную
            </Link>
          </div>
        ) : (
          <div className="checkout">
            <div className="checkout__bill">
              {cartProducts.length === 0 ? (
                <div className="empty-cart">
                  <img src={EmptyCart} alt="" />
                  <h3 className="empty-cart__head"> ВАША КОРЗИНА ПУСТА</h3>
                  <p className="empty-cart__desc">
                    Выберите товары из нашего каталога и добавьте их в корзину
                  </p>
                  <Link className="empty-cart__btn" to="/">
                    На главную
                  </Link>
                </div>
              ) : (
                <div className="checkout__block">
                  <div className="checkout-block__cart">
                    {cartProducts.map((item, index) => {
                      return (
                        <div className="" key={index}>
                          <CartItem item={item} />
                        </div>
                      );
                    })}
                  </div>

                  {cartProducts.length !== 0 && ( // Проверка наличия товаров в корзине
                    <div className="checkout__form-box">
                      <form className="checkout__form" onSubmit={submitHandler}>
                        <h6 className="checkout__form-total-text d-flex align-items-center justify-content-between mb-3">
                          Количество товаров:{" "}
                          <span>{cartProducts.length} шт</span>
                        </h6>
                        <h6 className="checkout__form-total-text d-flex align-items-center justify-content-between mb-3">
                          Стоимость заказа: <span>{cartTotalAmount} сом</span>
                        </h6>
                        <h6 className="checkout__form-total-text d-flex align-items-center justify-content-between mb-3">
                          Доставка: <span> от 3000 сом</span>
                        </h6>
                        <h6 className="checkout__form-total-text d-flex align-items-center justify-content-between mb-3">
                          Cумма заказа: <span> от 300$</span>
                        </h6>
                        <div className="checkout__total">
                          <h5 className="checkout__form-total d-flex align-items-center justify-content-between">
                            Итого: <span>{totalAmount} сом</span>
                          </h5>
                        </div>
                        {/* <input type="radio" name="" id="" />
                     <input type="radio" name="" id="" /> */}
                        <button
                          type="submit"
                          className="addTOCart__btn checkout__btn"
                        >
                          Заказать
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              )}
            </div>
            <Container>
              <form className="form" onSubmit={submitHandler}>
                <div className="message js-form-message">ОФОРМЛЕНИЕ ЗАКАЗА</div>
                <div className="form-block__name">
                  <div className="form-group">
                    <label htmlFor="" className="form-group__desc">
                      Имя*
                    </label>
                    <input
                      className="form-group__inputs"
                      type="text"
                      placeholder="Введите имя"
                      {...register("form_name", {
                        required: "Это поле необходимо заполнить",
                      })}
                    />
                    {errors.form_name && (
                      <p className="form-text__error">
                        {errors.form_name.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="" className="form-group__desc">
                      Фамилию*
                    </label>
                    <input
                      className="form-group__inputs"
                      type="text"
                      placeholder="Введите фамилию"
                      {...register("form_firstName", {
                        required: "Это поле необходимо заполнить",
                      })}
                    />
                    {errors.form_firstName && (
                      <p className="form-text__error">
                        {errors.form_firstName.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-block__name">
                  <div className="form-group">
                    <label htmlFor="" className="form-group__desc">
                      E-mail*
                    </label>
                    <input
                      className="form-group__inputs"
                      type="email"
                      placeholder="Введите E-mail"
                      {...register("form_email", {
                        required: "Это поле необходимо заполнить",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Введите корректный адрес электронной почты",
                        },
                      })}
                    />
                    {errors.form_email && (
                      <p className="form-text__error">
                        {errors.form_email.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="" className="form-group__desc">
                      Телефон*
                    </label>
                    <input
                      className="form-group__inputs"
                      type="tel"
                      placeholder="Введите телефон"
                      {...register("form_tel", {
                        required: "Это поле необходимо заполнить",
                        pattern: {
                          value: /^\d+$/,
                          message: "Введите только цифры",
                        },
                      })}
                    />
                    {errors.form_tel && (
                      <p className="form-text__error">
                        {errors.form_tel.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group-box">
                  <label>Выберите оплату:</label>
                  <div className="form-group__check">
                    <label className="form-group__check__block">
                      <Radio
                        {...controlProps("option1")}
                        sx={{
                          color: pink[800],
                          "&.Mui-checked": {
                            color: pink[600],
                          },
                        }}
                        id="option1"
                      />
                      <label
                        className="form-group__check__text"
                        htmlFor="option1"
                      >
                        Переводы через VISA
                      </label>
                    </label>
                    <label className="form-group__check__block">
                      <Radio
                        {...controlProps("option2")}
                        sx={{
                          color: pink[800],
                          "&.Mui-checked": {
                            color: pink[600],
                          },
                        }}
                        id="option2"
                      />
                      <label
                        className="form-group__check__text"
                        htmlFor="option2"
                      >
                        НА КАРТУ СБЕРБАНК
                      </label>
                    </label>
                  </div>
                  <div className="form-group__check">
                    <label className="form-group__check__block">
                      <Radio
                        {...controlProps("option3")}
                        sx={{
                          color: pink[800],
                          "&.Mui-checked": {
                            color: pink[600],
                          },
                        }}
                        id="option3"
                      />
                      <label
                        className="form-group__check__text"
                        htmlFor="option3"
                      >
                        СИСТЕМА ДЕНЕЖНЫХ ПЕРЕВОДОВ
                      </label>
                    </label>
                    <label className="form-group__check__block">
                      <Radio
                        {...controlProps("option4")}
                        sx={{
                          color: pink[800],
                          "&.Mui-checked": {
                            color: pink[600],
                          },
                        }}
                        id="option4"
                      />
                      <label
                        className="form-group__check__text"
                        htmlFor="option4"
                      >
                        Расчетный счет
                      </label>
                    </label>
                  </div>
                </div>
                <div className="form-group-btn">
                  <button type="submit" className="form-btn">
                    Отправить
                  </button>
                </div>
              </form>
            </Container>
          </div>
        )}
        <Container>
          <div className="checkout-collections" style={{marginBottom: "80px"}}>
            <Collections />
          </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default Checkout;
