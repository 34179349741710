// ProductCard.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../../store/shopping-cart/cartSlice";
import "../../../styles/product-card.css";
import "animate.css";
import "../../../styles/shopping-cart.css";
import "../../../styles/product-details.css";
import icon__favorites from "../../../assets/images/icon__favorites.png";
import icon__favorites_1 from "../../../assets/images/favorite.png";
import { useFavorite } from "../../../store/FavoritesActions";
import photo from "../../../assets/images/photo.png";
import { Alert } from "@mui/material";
import { useAlert } from "../Alert/AlertContext ";
import { Link } from "react-router-dom";
import axios from "axios";
const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const { addToFavorites, removeFromFavorites, favorites } = useFavorite();
  const isFavorite = favorites.some((fav) => fav.id === product.id);
  const showAlert = useAlert();
  const [message, setMessage] = useState("");


  return (
    <Link
      to={`/PersonalCard/${product.id}`}
      className="card__unit"
      key={product.id}
    >
      {/* <img src={logo_card} className="card__unit-logo" alt="" /> */}

      <img src={product.images} className="card__unit-image" alt="" />
      {/* <img
        src={`https://api.moresushikg.shop/static/uploads/${product.photo_path}`}
        className="card__unit-image"
        alt=""
      /> */}
      <div className="cart1 animate__animated animate__fadeInUp animate__faster">
        <div className="card__unit-desc-favorites">
          <h4 className="card__unit-desc"> Автор:  <span className="card__unit-span">"{product.author}"</span></h4>
        </div>
        <p className="card__unit-title">
          Название: <span className="card__unit-span">"{product.title}"</span>
        </p>
        <p className="card__unit-title">
          Жанр: <span className="card__unit-span">"{product.genres.name}"</span>
        </p>
        <p className="card__unit-title">
          Год книги: <span className="card__unit-span">"{product.year}"</span>
        </p>

        <button className="card_unit-btn">Подробнее</button>
      </div>
    </Link>
  );
};

export default ProductCard;
