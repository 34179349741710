import React, { useState, useEffect } from "react";
import axios from "axios";
import Chart from "react-apexcharts";
import { Container } from "reactstrap";
import "../styles/Static.css";
import {baseApi} from "../api";

const StaticGraf = () => {
    const [directions, setDirections] = useState([]);

    useEffect(() => {

        axios
            .get(`${baseApi}/stats/`)
            .then((response) => {
                setDirections(response.data.sort((u) => u.books_count));
            })
            .catch((error) => {
                console.error("Error fetching products:", error);
            });
    }, []);

    // Преобразование данных для диаграммы Pie Chart
    const getChartData = () => {
        const labels = directions.map((direction) => direction.name);
        const series = directions.map((direction) => direction.books_count);

        return {
            labels,
            series,
        };
    };

    // Проверяем наличие данных перед вызовом getChartData()
    const chartData =
        directions.length > 0 ? getChartData() : { labels: [], series: [] };

    const chartOptions = {
        labels: chartData.labels,
        legend: {
            show: true,
            position: "bottom",
        },
    };

    return (
        <div>
            <Container>
                <div className="chart-static">
                    <Chart
                        options={chartOptions}
                        series={chartData.series}
                        type="pie"
                        width="100%"
                    />
                </div>
            </Container>
        </div>
    );
};

export default StaticGraf;
