import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductCard from "../components/UI/product-card/ProductCard";
import CardDetails from "./CardDetails";
import "../styles/slider.css";
import FoodDetails from "./FoodDetails";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {LinearProgress} from "@mui/material";

const PersonalCard = ({product}) => {
  const [book, setBook] = useState(null)
  const { id } = useParams();

  useEffect(() => {
    axios.get(`https://api.lib-intuit.online/api/v1/books/${id}/`)
        .then((res) => setBook(res.data))
        .catch((err) => console.log(err))
  }, [id, setBook]); // Заменяем categoryName на productName


  return (
    <div className="personal">
      <div className="personal-card">
          {book ? <FoodDetails product={book}/> : <Box sx={{ display: 'flex' }}><LinearProgress /></Box>}
      </div>
      <div className="personal-card__recom">
        <h3 className="personal-card__recom-text">
          Вам также может понравиться!
        </h3>
        <CardDetails product={product}/>
      </div>
    </div>
  );
};

export default PersonalCard;
